const DocumentsData = [
  {
    checkbox: true,
    documentstype: 'Docs',
    documentsname: 'SK Overseas Docs File',
    filesize: '2.5MB',
    modifydate: '15 Feb, 2023',
    uploaded: 'Admin',
    status: 'Successful',
    bgcolor: 'bg-green-100',
    textcolor: 'text-green-500 dark:bg-green-500/20',
  },
  {
    checkbox: true,
    documentstype: 'PSD',
    documentsname: 'SK Overseas Design Kit.psd',
    filesize: '234.87 MB',
    modifydate: '29 Jan, 2023',
    uploaded: 'Themesdesign',
    status: 'Successful',
    bgcolor: 'bg-green-100',
    textcolor: 'text-green-500 dark:bg-green-500/20',
  },
  {
    checkbox: true,
    documentstype: 'SVG',
    documentsname: 'home Pattern Wave.svg',
    filesize: '3.87 MB',
    modifydate: '24 Sept, 2023',
    uploaded: 'Admin',
    status: 'Error',
    bgcolor: 'bg-red-100',
    textcolor: 'text-red-500 dark:bg-red-500/20',
  },
  {
    checkbox: true,
    documentstype: 'SCSS',
    documentsname: 'tailwind.scss',
    filesize: '0.100 KB',
    modifydate: '03 April, 2023',
    uploaded: 'Paula',
    status: 'Successful',
    bgcolor: 'bg-green-100',
    textcolor: 'text-green-500 dark:bg-green-500/20',
  },
  {
    checkbox: true,
    documentstype: 'MP4',
    documentsname: 'SK Overseas Guide Video.mp4',
    filesize: '149.33 MB',
    modifydate: '12 Nov, 2023',
    uploaded: 'Themesdesign',
    status: 'Pending',
    bgcolor: 'bg-yellow-100',
    textcolor: 'text-yellow-500 dark:bg-yellow-500/20',
  },
];

const ProjectsData = [
  {
    avatar: 'assets/images/brand/adwords.png',
    title: 'Chat App',
    description:
      'Allows you to communicate with your customers in web chat rooms.',
    dueDate: '16 July, 2023',
    budget: '$8,740.00',
    progress: 25,
    color: 'bg-custom-500',
  },
  {
    avatar: 'assets/images/brand/app-store.png',
    title: 'Business Template - UI/UX design',
    description:
      'UX design process is iterative and non-linear, includes a lot of research.',
    dueDate: '28 Nov, 2023',
    budget: '$10,254.00',
    progress: 61,
    color: 'bg-sky-500',
  },
  {
    avatar: 'assets/images/brand/android.png',
    title: 'ABC Project Customization',
    description:
      'The process of tailoring the overall project delivery process to meet the requirements.',
    dueDate: '20 Oct, 2023',
    budget: '$9,832.00',
    progress: 87,
    color: 'bg-green-500',
  },
  {
    avatar: 'assets/images/brand/figma.png',
    title: 'SK Overseas Design',
    description:
      'Drawing created with Microsoft Expression Design, a drawing and design program for Windows.',
    dueDate: '07 Dec, 2023',
    budget: '$11,971.00',
    progress: 65,
    color: 'bg-purple-500',
  },
  {
    avatar: 'assets/images/brand/gmail.png',
    title: 'HR Management',
    description:
      'The strategic approach to nurturing and supporting employees and ensuring a positive.',
    dueDate: '02 Jan, 2024',
    budget: '$7,546.00',
    progress: 65,
    color: 'bg-purple-500',
  },
  {
    avatar: 'assets/images/brand/meta.png',
    title: 'Finance Apps',
    description:
      'A personal budget app is a technology solution that is connected.',
    dueDate: '10 Feb, 2024',
    budget: '$13,745.00',
    progress: 65,
    color: 'bg-purple-500',
  },
  {
    avatar: 'assets/images/brand/search.png',
    title: 'Mailbox Design',
    description:
      'An email template is an HTML preformatted email that you can use to create your own.',
    dueDate: '19 Feb, 2024',
    budget: '$9,120.00',
    progress: 65,
    color: 'bg-purple-500',
  },
  {
    avatar: 'assets/images/brand/twitter.png',
    title: 'Banking Management',
    description:
      "Bank management refers to the process of managing the Bank's statutory activity.",
    dueDate: '01 March, 2024',
    budget: '$24,863.00',
    progress: 65,
    color: 'bg-purple-500',
  },
];

const FollowersData = [
  {
    title: 'Executive Operations',
    roleColor: 'text-custom-600',
    roleBgColor: 'bg-custom-100',
    doj: 'Doj:15 Jan, 2023',
    name: 'Ralaphe Flores',
    email: 'floral12@skoverseas.com',
    phone: '+213 617 219 6245',
    experience: 'Exp. : 1.5 years',
    salary: 'Salary : $463.42',
    salaryFrequency: '/ Month',
    avatar: 'assets/images/users/avatar-3.png',
  },
  {
    title: 'Project Manager',
    roleColor: 'text-green-600',
    roleBgColor: 'bg-green-100',
    doj: 'Doj:29 Feb, 2023',
    name: 'James Lash',
    email: 'jameslash@skoverseas.com',
    phone: '+210 85 383 2388',
    experience: 'Exp. : 0.5 years',
    salary: 'Salary : $701.77',
    salaryFrequency: '/ Month',
    avatar: 'assets/images/users/avatar-2.png',
  },
  {
    title: 'React Developer',
    roleColor: 'text-sky-600',
    roleBgColor: 'bg-sky-100',
    doj: 'Doj:04 March, 2023',
    name: 'Angus Garnsey',
    email: 'angusgarnsey@skoverseas.com',
    phone: '+210 41521 1325',
    experience: 'Exp. : 0.7 years',
    salary: 'Salary : $478.32',
    salaryFrequency: '/ Month',
    avatar: 'assets/images/users/avatar-4.png',
  },
  {
    title: 'Shopify Developer',
    roleColor: 'text-yellow-600',
    roleBgColor: 'bg-yellow-100',
    doj: 'Doj:11 March, 2023',
    name: 'Matilda Marston',
    email: 'matildamarston@skoverseas.com',
    phone: '+210 082 288 1065',
    experience: 'Exp. : 1 years',
    salary: 'Salary : $120.37',
    salaryFrequency: '/ Month',
    avatar: 'assets/images/users/avatar-5.png',
  },
  {
    title: 'Angular Developer',
    roleColor: 'text-red-600',
    roleBgColor: 'bg-red-100',
    doj: 'Doj:22 March, 2023',
    name: 'Zachary Benjamin',
    email: 'zacharybenjamin@skoverseas.com',
    phone: '+120 348 9730 237',
    experience: 'Exp. : 0 years',
    salary: 'Salary : $89.99',
    salaryFrequency: '/ Month',
    avatar: 'assets/images/users/avatar-6.png',
  },
  {
    title: 'Graphic Designer',
    roleColor: 'text-purple-600',
    roleBgColor: 'bg-purple-100',
    doj: 'Doj:09 June, 2023',
    name: 'Ruby Chomley',
    email: 'rubychomley@skoverseas.com',
    phone: '+120 1234 56789',
    experience: 'Exp. : 0.2 years',
    salary: 'Salary : $214.82',
    salaryFrequency: '/ Month',
    avatar: 'assets/images/users/avatar-7.png',
  },
  {
    title: 'Shopify Developer',
    roleColor: 'text-yellow-600',
    roleBgColor: 'bg-yellow-100',
    doj: 'Doj:27 June, 2023',
    name: 'Jesse Edouardy',
    email: 'jessedouard@skoverseas.com',
    phone: '+87 044 017 3869',
    experience: 'Exp. : 1.7 years',
    salary: 'Salary : $278.96',
    salaryFrequency: '/ Month',
    avatar: 'assets/images/users/avatar-8.png',
  },
  {
    title: 'Team Leader',
    roleColor: 'text-orange-600',
    roleBgColor: 'bg-orange-100',
    doj: 'Doj:15 July, 2023',
    name: 'Xavier Bower',
    email: 'xavierbower@skoverseas.com',
    phone: '+159 98765 32451',
    experience: 'Exp. : 6.7 years',
    salary: 'Salary : $901.94',
    salaryFrequency: '/ Month',
    avatar: 'assets/images/users/avatar-9.png',
  },
];

export { DocumentsData, ProjectsData, FollowersData };
