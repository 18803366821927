
const mailbox = [{
    "id": 1,
    "name": "Tonya Johnson",
    "subject": "Maintain the overall pristine status...",
    "time": "10:59 AM",
    "unread": true,
    "active": true
},
{
    "id": 2,
    "name": "Infra Teach",
    "subject": "How Custom Software Can Solve Your Business Challenges.",
    "time": "04:15 PM",
    "unread": false,
    "active": true
},

{
    "id": 3,
    "name": "Facebook",
    "subject": "Post Basic High School, Ratanpur(GA) and 7 others are new Group suggestions for you",
    "time": "12:29 PM",
    "unread": false,
    "active": true
},
{
    "id": 4,
    "name": "Linkedin",
    "subject": "Front End Developer-HTML/CSS role at Gainserv: 1 connection",
    "time": "08:44 PM",
    "unread": false,
    "active": true
},
{
    "id": 5,
    "name": "Jobcy - Job Board & Landing Page",
    "subject": "You’re invited: Job Search Tactics for Entry-Level Candidates",
    "time": "08:10 PM",
    "unread": false,
    "active": true
},
{
    "id": 6,
    "name": "Holly Kavanaugh",
    "subject": "Information Technology Rules Annual Reminder",
    "time": "04:33 PM",
    "unread": true,
    "active": true
},
{
    "id": 7,
    "name": "Jose White",
    "subject": "I just requested to connect - Paula Keenan, respond to Meet’s now.",
    "time": "01:19 PM",
    "unread": false,
    "active": true
},
{
    "id": 8,
    "name": "Patricia Garcia",
    "subject": "List all mail-enabled users who have specific permissions on the selected mailboxes, in other words their delegates.",
    "time": "01:20 AM",
    "unread": true,
    "active": true
},
{
    "id": 9,
    "name": "Tonya Johnson",
    "subject": "Maintain the overall pristine status and health of your Exchange Online environment with M365 Manager Plus’ reports on the number of inactive mailboxes.",
    "time": "10:57 AM",
    "unread": false,
    "active": true
},
{
    "id": 10,
    "name": "Themesdesign",
    "subject": "Your License expired",
    "time": "11:54 PM",
    "unread": true,
    "active": true
},
{
    "id": 1,
    "name": "Tonya Johnson",
    "subject": "Maintain the overall pristine status...",
    "time": "10:59 AM",
    "unread": true,
    "active": true
},
{
    "id": 2,
    "name": "Infra Teach",
    "subject": "How Custom Software Can Solve Your Business Challenges.",
    "time": "04:15 PM",
    "unread": false,
    "active": true
},

{
    "id": 3,
    "name": "Facebook",
    "subject": "Post Basic High School, Ratanpur(GA) and 7 others are new Group suggestions for you",
    "time": "12:29 PM",
    "unread": false,
    "active": true
},
{
    "id": 4,
    "name": "Linkedin",
    "subject": "Front End Developer-HTML/CSS role at Gainserv: 1 connection",
    "time": "08:44 PM",
    "unread": false,
    "active": true
},
{
    "id": 5,
    "name": "Jobcy - Job Board & Landing Page",
    "subject": "You’re invited: Job Search Tactics for Entry-Level Candidates",
    "time": "08:10 PM",
    "unread": false,
    "active": true
},
{
    "id": 6,
    "name": "Holly Kavanaugh",
    "subject": "Information Technology Rules Annual Reminder",
    "time": "04:33 PM",
    "unread": true,
    "active": true
},
{
    "id": 7,
    "name": "Jose White",
    "subject": "I just requested to connect - Paula Keenan, respond to Meet’s now.",
    "time": "01:19 PM",
    "unread": false,
    "active": true
},
{
    "id": 8,
    "name": "Patricia Garcia",
    "subject": "List all mail-enabled users who have specific permissions on the selected mailboxes, in other words their delegates.",
    "time": "01:20 AM",
    "unread": true,
    "active": true
},
{
    "id": 9,
    "name": "Tonya Johnson",
    "subject": "Maintain the overall pristine status and health of your Exchange Online environment with M365 Manager Plus’ reports on the number of inactive mailboxes.",
    "time": "10:57 AM",
    "unread": false,
    "active": true
},
{
    "id": 10,
    "name": "Themesdesign",
    "subject": "Your License expired",
    "time": "11:54 PM",
    "unread": true,
    "active": true
},

]

export { mailbox }